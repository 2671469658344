import React, { type ReactElement, useContext } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { TravelHomePage } from '../pages/TravelHomePage';
import { NotFounfPage } from '../pages/NotFoundPage';
import { NoTravel } from '../components/NoTravel';
import { AdminPage } from '../pages/AdminPage';
import { Layout } from '../components/Layout';
import { TravelContext } from '../store/TravelContext';
import { TravelByIdPage } from '../pages/TravelByIdPage';

function App(): ReactElement<React.FC> {
  const request = {
    scopes: ['User.Read'],
  };
  const travelCtx = useContext(TravelContext);
  useMsalAuthentication(InteractionType.Redirect, request);
  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<TravelHomePage />} />
            <Route path='/:id' element={<TravelByIdPage />} />
            <Route path='/notravel' element={<NoTravel />} />
            {(travelCtx.isAdmin || travelCtx.isSuperAdmin) && (
              <Route path='/admin' element={<AdminPage />} />
            )}
            {/* <Route
              path='/admin'
              element={
                travelCtx.isAdmin || travelCtx.isSuperAdmin ? <AdminPage /> : <NotFounfPage />
              }
            /> */}
            <Route path='404' element={<NotFounfPage />} />
            <Route path='*' element={<NotFounfPage />} />
          </Route>
        </Routes>
      </AuthenticatedTemplate>
    </BrowserRouter>
  );
}
export default App;
