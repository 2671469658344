/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { type ReactElement, useMemo, useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Typography, Stack, Button, CircularProgress } from '@mui/material';
import { TravelStagePage } from './TravelStagePage';
import { TravelContext } from '../store/TravelContext';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { ConvertDateUtil } from '../utils/ConvertDateUtil';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { GetStageUtil } from '../utils/GetStageUtil';
import { Toast } from '../components/Toast';

export type Travel = {
  id: number;
  traveler_name: string;
  email_address: string;
  origin_country: string;
  destination_country: string;
  travel_start_date: string;
  travel_end_date: string;
  ritm_number: any;
  loaner_device: any;
  travel_stage: {
    name: string;
    stage: number;
  };
};

export const TravelHomePage = (): ReactElement<React.FC> => {
  let context;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForTravel, setIsLoadingForTravel] = useState(false);
  const travelCtx = useContext(TravelContext);
  const [rows, setRows] = useState<Travel[]>([]);
  const { instance, accounts } = useMsal();
  const [toastState, setToastState] = React.useState({
    open: false,
    type: 'success',
    message: '',
  });
  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };
  const getTravelRecords = async (): Promise<void> => {
    try {
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const email = accounts[0].username.toLocaleUpperCase();
      const response: any = await axios
        .get(`${apiUrl}/travelDetails/email/${email}`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      setIsLoading(false);
      if (response.data.msg.length === 0) {
        navigate('/notravel');
      }
      response.data.msg.map(
        (travel: Travel) =>
          (travel.travel_stage = GetStageUtil(travel.travel_start_date, travel.travel_end_date))
      );
      response.data.msg.sort((a: Travel, b: Travel) => a.travel_stage.stage - b.travel_stage.stage);
      if (travelCtx.getRouteId !== 0) {
        let fromIndex = -1;
        response.data.msg.find((item: any, index: number) => {
          if (item.id === Number(travelCtx.getRouteId)) {
            fromIndex = index;
            return index;
          }
        });
        const element = response.data.msg.splice(fromIndex, 1)[0];
        response.data.msg.unshift(element);
      }
      setRows(response.data.msg);
    } catch (err) {
      console.log('Error', err);
      setIsLoading(false);
      setToastState({ open: true, message: 'Failed to fetch the record', type: 'error' });
    }
  };
  const directRouteById = async (id: number) => {
    const travelRecordById: any = await getTravelRecordsById(id);
    if (
      travelRecordById &&
      accounts[0].username.toLocaleUpperCase() ===
        travelRecordById.email_address.toLocaleUpperCase()
    ) {
      travelRecordById.travel_stage = GetStageUtil(
        travelRecordById.travel_start_date,
        travelRecordById.travel_end_date
      );
      setState({ ...state, stage: travelRecordById.travel_stage.stage, ...travelRecordById });
      travelCtx.setTravelRecords({
        ...travelRecordById,
        travel_stage: travelRecordById.travel_stage,
      });
      setToggleShow(1);
    }
  };
  const getTravelRecordsById = async (id: number): Promise<void> => {
    try {
      setIsLoadingForTravel(true);
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const config = {
        method: 'get',
        url: `${apiUrl}/travelDetails/id/${id}`,
        headers: {
          Authorization: `Bearer ${token?.idToken}`,
          'Content-Type': 'application/json',
          graph_token: token?.accessToken,
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await axios(config);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      setIsLoadingForTravel(false);
      return response.data.msg[0];
    } catch (err) {
      setIsLoadingForTravel(false);
      setToggleShow(0);
      setToastState({ open: true, message: 'Failed to fetch the record', type: 'error' });
      console.log('Error', err);
    }
  };
  useEffect((): void => {
    (async (): Promise<void> => {
      await getTravelRecords();
      if (travelCtx.getRouteId !== 0) await directRouteById(travelCtx.getRouteId);
      travelCtx.setRouteId(0);
    })();
  }, []);
  const [toggleShow, setToggleShow] = useState(0);
  const [state, setState] = useState({
    id: 0,
    stage: 0,
    origin_country: '',
    destination_country: '',
    travel_start_date: '',
    travel_end_date: '',
    ritm_number: '',
  });
  const handleOnclick = async (data: Travel) => {
    const travelData: any = await getTravelRecordsById(data.id);
    if (travelData) {
      setState({ ...state, stage: data.travel_stage.stage, ...travelData });
      travelCtx.setTravelRecords({ ...travelData, travel_stage: data.travel_stage });
      setToggleShow(1);
    }
  };
  const columns = useMemo<MRT_ColumnDef<Travel>[]>(
    () => [
      {
        accessorKey: 'origin_country',
        header: 'Origin Place',
      },
      {
        accessorKey: 'destination_country',
        header: 'Destination',
      },
      {
        accessorKey: 'travel_start_date',
        header: 'Travel Start Date',
        Cell: ({ row }) => ConvertDateUtil(row.original.travel_start_date),
      },
      {
        accessorKey: 'travel_end_date',
        header: 'Travel End Date',
        Cell: ({ row }) => ConvertDateUtil(row.original.travel_end_date),
      },
      {
        accessor: 'status',
        header: 'Status',
        Cell: ({ row }) => row.original.travel_stage.name,
      },
      {
        accessor: 'action',
        header: 'Action',
        minSize: 150,
        Cell: ({ row }) => (
          <Button
            color='inherit'
            variant='outlined'
            sx={{
              fontSize: { xs: '0.5rem', sm: '0.75rem' },
              color: '#F69A19',
            }}
            onClick={() => handleOnclick(row.original)}
          >
            Go to {row.original.travel_stage.name}
          </Button>
        ),
      },
    ],
    []
  );
  const secondLoader = (
    <Grid
      sx={{ mt: '2rem' }}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress size='3rem' />
    </Grid>
  );
  if (isLoading) {
    context = (
      <Grid
        sx={{ mt: '20rem', ml: '48%' }}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <CircularProgress size='6rem' />
      </Grid>
    );
  } else {
    context = (
      <>
        <Grid
          xs={15}
          sm={13}
          lg={8}
          container
          justifyContent='flex-start'
          mdOffset={2}
          direction='column'
        >
          <Grid width='100%'>
            <Stack spacing={1}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '1.10rem',
                    fontWeight: 'bold',
                  }}
                >
                  TRAVEL DETAILS
                </Typography>
              </Stack>
              <Stack>
                <MaterialReactTable
                  columns={columns}
                  enablePinning
                  initialState={{
                    columnPinning: { right: ['Action'] },
                    pagination: { pageSize: 5, pageIndex: 0 },
                  }}
                  enableColumnActions={false}
                  enableTopToolbar={false}
                  enableSorting={false}
                  muiTablePaperProps={{
                    sx: { px: '1rem', pt: '1.5rem' },
                  }}
                  data={rows}
                  defaultColumn={{
                    size: 80,
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      bgcolor: '#f1f1f1',
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    hover: false,
                    sx: {
                      bgcolor: toggleShow && row.original.id === state.id ? '#E5F6FC' : '',
                    },
                  })}
                />
              </Stack>
            </Stack>
            {toggleShow ? (
              isLoadingForTravel ? (
                secondLoader
              ) : (
                <TravelStagePage travelData={state} />
              )
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {toastState.open && (
          <Toast
            open={toastState.open}
            handleClose={() => setToastState({ ...toastState, open: false })}
            message={toastState.message}
            type={toastState.type}
          />
        )}
      </>
    );
  }
  return context;
};
