import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import PreTravel from '../components/PreTravel';
import { DuringTravel } from '../components/DuringTravel';
import { PostTravel } from '../components/PostTravel';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import SvgIcon from '@mui/material/SvgIcon';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';
import { ReactComponent as flightLogo } from '../assets/flight.svg';
import { ReactComponent as calenderLogo } from '../assets/calender.svg';
import { Typography } from '@mui/material';
import { ConvertDateUtil } from '../utils/ConvertDateUtil';
import { TravelContext } from '../store/TravelContext';

const stages = [
  {
    label: 'PRE TRAVEL',
    component: <PreTravel />,
  },
  {
    label: 'DURING TRAVEL',
    component: <DuringTravel />,
  },
  {
    label: 'POST TRAVEL',
    component: <PostTravel />,
  },
];
const QontoStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#00A1DE',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#45BA7F',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'white',
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? <Check className='QontoStepIcon-completedIcon' /> : <span>{icon}</span>}
    </QontoStepIconRoot>
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TravelStagePage = (prop: any) => {
  const [activeStep, setActiveStep] = React.useState(prop.travelData.stage);
  const travelCtx = React.useContext(TravelContext);
  React.useEffect(() => {
    setActiveStep(prop.travelData.stage);
    window.scrollTo({ top: document.body.scrollHeight / 5, behavior: 'smooth' });
  }, [prop.travelData.stage]);
  const handleStep = (step: number, stage: number) => () => {
    if (stage >= step) setActiveStep(step);
  };
  const checkColor = (activeStage: number, index: number) => {
    if (
      activeStage > index ||
      (Number(prop.travelData.stage) === 2 &&
        (travelCtx.travelRecords.extra_details?.other_details !== undefined ||
          travelCtx.travelRecords.ritm_number === null))
    ) {
      return '#45BA7F';
    } else if (activeStage === index) {
      return '#00A1DE';
    } else {
      return 'grey';
    }
  };
  return (
    <>
      <Grid width='100%' sx={{ my: '1.5rem' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {stages.map((stage, index) => (
            <Step
              key={stage.label}
              completed={
                Number(prop.travelData.stage) > index ||
                (Number(prop.travelData.stage) === 2 &&
                  (travelCtx.travelRecords.extra_details?.other_details !== undefined ||
                    travelCtx.travelRecords.ritm_number === null))
              }
            >
              <StepLabel
                color='inherit'
                StepIconComponent={QontoStepIcon}
                onClick={handleStep(index, prop.travelData.stage)}
              >
                <Typography
                  sx={{
                    color: checkColor(prop.travelData.stage, index),
                    fontSize: { xs: '0.9rem ', sm: '1.125rem' },
                    cursor: prop.travelData.stage >= index ? 'pointer' : '',
                  }}
                >
                  {stage.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid width='100%' sx={{ boxShadow: 2, p: { md: 3, sm: 3, xs: 1 }, backgroundColor: '#fff' }}>
        <Grid container spacing={1} sx={{ py: 1, mb: 2, mx: 3, backgroundColor: '#f1f1f1' }}>
          <Grid container display='flex' md={3} sm={6} xs={12}>
            <SvgIcon
              component={flightLogo}
              sx={{
                height: { xs: '1rem', sm: '1rem', md: '1rem' },
                m: '0.5rem',
              }}
            />
            <Typography fontWeight='medium' variant='subtitle1'>
              Origin -
            </Typography>
            <Typography color='secondary' variant='subtitle1' sx={{ pl: '0.25rem' }}>
              {prop.travelData.origin_country}
            </Typography>
          </Grid>
          <Grid
            container
            display='flex'
            md={3}
            sm={6}
            xs={12}
            sx={{ p: { xs: '4px 0px 0px 44px' } }}
          >
            <Typography fontWeight='medium' variant='subtitle1'>
              Destination -
            </Typography>
            <Typography color='secondary' variant='subtitle1' sx={{ pl: '0.25rem' }}>
              {prop.travelData.destination_country}
            </Typography>
          </Grid>
          <Grid
            container
            display='flex'
            md={3}
            sm={6}
            xs={12}
            sx={{ p: { sm: '4px 0px 0px 8px', xs: '4px 0px 0px 10px' } }}
          >
            <SvgIcon
              component={calenderLogo}
              sx={{
                width: 'unset',
                height: { xs: '1rem', sm: '1rem', md: '1rem' },
                m: '0.5rem',
                fill: 'white',
              }}
            />
            <Typography fontWeight='medium' variant='subtitle1'>
              Travel Start -
            </Typography>
            <Typography color='secondary' variant='subtitle1' sx={{ pl: '0.25rem' }}>
              {ConvertDateUtil(prop.travelData.travel_start_date)}
            </Typography>
          </Grid>
          <Grid
            container
            display='flex'
            md={3}
            sm={6}
            xs={12}
            sx={{ p: { xs: '4px 0px 0px 42px' } }}
          >
            <Typography fontWeight='medium' variant='subtitle1'>
              Travel End -
            </Typography>
            <Typography color='secondary' variant='subtitle1' sx={{ pl: '0.25rem' }}>
              {ConvertDateUtil(prop.travelData.travel_end_date)}
            </Typography>
          </Grid>
        </Grid>
        {stages[activeStep].component}
      </Grid>
    </>
  );
};
