/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type ReactElement, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography, Grid, CircularProgress } from '@mui/material';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';

export const NoTravel = (): ReactElement<React.FC> => {
  let context;
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };
  const getTravelRecords = async (): Promise<void> => {
    try {
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const email = accounts[0].username.toLocaleUpperCase();
      const response: any = await axios
        .get(`${apiUrl}/travelDetails/email/${email}`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      setIsLoading(false);
      if (response.data.msg.length > 0) {
        navigate('/');
      }
    } catch (err) {
      setIsLoading(false);
      console.log('Error', err);
    }
  };
  useEffect((): void => {
    (async (): Promise<void> => {
      await getTravelRecords();
    })();
  }, []);
  if (isLoading) {
    context = (
      <Grid
        sx={{ mt: '20rem' }}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <CircularProgress size='6rem' />
      </Grid>
    );
  } else {
    context = (
      <Grid
        data-testid='NoTravelContainer'
        container
        justifyContent='center'
        direction='column'
        width='50%'
        alignItems='center'
        sx={{
          ml: '25%',
          fontWeight: 'medium',
          color: 'text.secondary',
          boxShadow: 2,
          backgroundColor: '#fff',
        }}
      >
        <Grid>
          <AirplanemodeInactiveIcon sx={{ fontSize: { xs: '5rem', sm: '10rem' } }} />
        </Grid>
        <Grid>
          <Typography
            variant='h5'
            color='secondary'
            sx={{
              textAlign: 'center',
              fontSize: { xs: '1rem', sm: '2rem' },
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            There is no upcoming travel
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return context;
};
