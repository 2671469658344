/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { type ReactElement, useMemo, useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import {
  Typography,
  Stack,
  Button,
  IconButton,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  ConvertAdminConfigurationReq,
  ConvertAdminConfigurationRes,
} from '../utils/CovertIataUtil';
import axios from 'axios';
import { AirportCodesModel } from './AirportCodesModel';
import { ConfirmationModal } from './ConfirmationModal';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { Toast } from './Toast';

export type Country = {
  name: string;
  airportCodes: string[];
};

export const TravelConfiguration = (): ReactElement<React.FC> => {
  let context;
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [hrg, setHrg] = useState({});
  const [rows, setrows] = useState<Country[]>([]);
  const [iata, setIata] = useState<Country[]>([]);
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [edit, setEdit] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };
  const getSavedHRGData = async (allData: any): Promise<void> => {
    try {
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const response: any = await axios
        .get(`${apiUrl}/sa/locationConfig/getAll/include_destination_countries`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (response.data.status !== 200 && typeof response.data.msg !== 'object') {
        throw Error(response.data.msg);
      }
      setrows(ConvertAdminConfigurationReq(response.data.msg[0].extra_details, allData));
      setHrg(response.data.msg[0].extra_details);
    } catch (err) {
      console.log('Error in fetching saved admin hgr data', err);
      setIsLoading(false);
    }
  };
  const getHRGData = async (): Promise<void> => {
    try {
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const response: any = await axios
        .get(`${apiUrl}/sa/findCountryWiseAirportCode`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (
        response.data.status !== 200 &&
        typeof response.data.msg !== 'object' &&
        Object.keys(response.data.msg).length === 0
      ) {
        throw Error(response.data.msg);
      }
      setIata(ConvertAdminConfigurationReq(response.data.msg));
      await getSavedHRGData(response.data.msg);
      setIsLoading(false);
    } catch (err) {
      console.log('Error in fetching iata data', err);
      setIsLoading(false);
    }
  };
  useEffect((): void => {
    (async (): Promise<void> => {
      await getHRGData();
    })();
  }, []);
  /* istanbul ignore next */
  const saveHRGData = async (req: any): Promise<void> => {
    try {
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const config = {
        method: 'post',
        url: `${apiUrl}/sa/locationConfig/includeCountries`,
        headers: {
          Authorization: `Bearer ${token?.idToken}`,
          'Content-Type': 'application/json',
          graph_token: token?.accessToken,
        },
        data: {
          config: req,
        },
      };
      const response = await axios(config);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      setToastState({
        open: true,
        message: 'Successfully updated',
        type: 'success',
      });
    } catch (error) {
      setToastState({
        open: true,
        message: 'Failed to Add or Update HRG Country',
        type: 'error',
      });
      console.log('Error while Add or Update HRG Country', error);
    }
  };
  /* istanbul ignore next */
  const handleEditOrAdd = async (data: any) => {
    try {
      await saveHRGData({ ...hrg, ...ConvertAdminConfigurationRes(data) });
      setOpen(false);
      setIsLoading(true);
      await getHRGData();
    } catch (err) {
      setIsLoading(false);
      console.log('Error in handleEditOrAdd method', err);
    }
  };
  /* istanbul ignore next */
  const handleDelete = async () => {
    try {
      const req: any = {};
      const tempData: any = { ...hrg };
      Object.keys(tempData)
        .filter((objKey) => objKey !== country)
        .reduce((newObj, key) => {
          newObj[key] = tempData[key];
          return newObj;
        }, req);
      await saveHRGData(req);
      setOpenConfirmation(false);
      setIsLoading(true);
      await getHRGData();
    } catch (err) {
      setIsLoading(false);
      console.log('Error in handleDelete method', err);
    }
  };
  /* istanbul ignore next */
  const handleDeleteConfirmation = (countryName: string) => {
    setCountry(countryName);
    setOpenConfirmation(true);
  };
  /* istanbul ignore next */
  const handleEdit = (data: any) => {
    setSelectedRow(data);
    setEdit(true);
    setOpen(true);
  };
  /* istanbul ignore next */
  const handleonAdd = () => {
    setSelectedRow({});
    setEdit(false);
    setOpen(true);
  };
  const columns = useMemo<MRT_ColumnDef<Country>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Country',
        maxSize: 50,
      },
      {
        accessorKey: 'airportCodes',
        header: 'Airport Code',
        maxSize: 200,
        Cell: ({ row }) => (
          <Box sx={{ overflowY: 'auto', width: '100%', height: '40px' }}>
            <TextField
              disabled
              variant='standard'
              value={row.original.airportCodes.toString()}
              multiline
              inputProps={{
                style: { fontSize: '0.875rem' },
              }}
              sx={{
                width: '100%',
                '& .MuiInput-root': {
                  '&:before, :after, :hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                  },
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000cc',
                },
              }}
            />
          </Box>
        ),
      },
      {
        accessor: 'action',
        header: 'Action',
        maxSize: 50,
        Cell: ({ row }) => (
          <Stack direction='row'>
            <IconButton onClick={() => handleEdit(row.original)} sx={{ color: '#F69A19' }}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteConfirmation(row.original.name)}
              sx={{ color: '#F69A19' }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Stack>
        ),
      },
    ],
    []
  );
  if (isLoading) {
    context = (
      <Grid
        data-testid='Spinner'
        sx={{ ml: '45%' }}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <CircularProgress size='6rem' />
      </Grid>
    );
  } else {
    context = (
      <Grid width='100%' height='100%' justifyContent='flex-start' sx={{ p: '5px' }}>
        <Stack spacing={1}>
          <Stack direction='row' justifyContent='space-between' sx={{ px: '1.5%' }}>
            <Typography
              sx={{
                fontSize: '1.10rem',
                fontWeight: 'bold',
                py: '0.5rem',
              }}
            >
              Recently added countries & airport codes
            </Typography>
            <Stack direction='row' spacing={1}>
              <Button
                data-testid='b1'
                color='primary'
                variant='outlined'
                onClick={() => handleonAdd()}
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                  bgcolor: '#F5FBFE',
                }}
              >
                Add
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  px: '1rem',
                  boxShadow: '0',
                },
              }}
              columns={columns}
              data={rows}
              enableTopToolbar={false}
              enableColumnActions={false}
              enableSorting={false}
              positionToolbarAlertBanner='none'
              muiTableHeadCellProps={{
                sx: {
                  bgcolor: '#f1f1f1',
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  background: 'white',
                },
              }}
              muiTableBodyRowProps={() => ({
                hover: false,
              })}
            />
          </Stack>
        </Stack>
        {open && (
          <AirportCodesModel
            airportCodes={iata}
            open={open}
            onClose={() => setOpen(false)}
            onAddOrEdit={handleEditOrAdd}
            selectedData={selectedRow}
            isEdit={edit}
          />
        )}
        {openConfirmation && (
          <ConfirmationModal
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
            handleDeleteConfirmation={handleDelete}
          />
        )}
        {toastState.open && (
          <Toast
            open={toastState.open}
            handleClose={() => setToastState({ ...toastState, open: false })}
            message={toastState.message}
            type={toastState.type}
          />
        )}
      </Grid>
    );
  }
  return context;
};
