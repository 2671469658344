/* eslint-disable @typescript-eslint/no-explicit-any */
export const FormatPreTravelRecords = (allData: any = {}) => {
  const travelDataArray = [];
  for (let i = 0; i < allData.length; i++) {
    const travelData: any = {
      traveler_name: allData[i].traveler_name,
      email_address: allData[i].email_address,
      origin_country: allData[i].origin_country,
      destination_country: allData[i].destination_country,
      travel_start_date: allData[i].travel_start_date,
      travel_end_date: allData[i].travel_end_date,
    };
    travelDataArray.push(travelData);
  }
  return travelDataArray;
};
export const FormatPostTravelRecords = (allData: any) => {
  const travelDataArray = [];
  for (let i = 0; i < allData.length; i++) {
    const travelData: any = {
      traveler_name: allData[i].traveler_name,
      email_address: allData[i].email_address,
      travel_start_date: allData[i].travel_start_date,
      travel_end_date: allData[i].travel_end_date,
      ritm_number: allData[i].ritm_number,
      ritm_status: allData[i].ritm_status,
      runbook_incident: allData[i].runbookIncident,
      security_incident: allData[i].securityIncident,
      passwordStatus: allData[i].passwordStatus,
    };
    travelDataArray.push(travelData);
  }
  return travelDataArray;
};
