/* eslint-disable @typescript-eslint/no-explicit-any */
type Country = {
  name: string;
  airportCodes: string[];
};
export const ConvertAdminConfigurationReq = (req: any, allData: any = {}) => {
  const res: Country[] = [];
  for (const [key, value] of Object.entries(req)) {
    const codesArr: string[] = Array.isArray(value) ? value : allData[key] || [];
    res.push({ name: key, airportCodes: codesArr });
  }
  return res;
};

export const ConvertAdminConfigurationRes = (req: any) => {
  const obj: any = {};
  if (req) {
    const name = req.name;
    const airportCodes = req.allSelected ? '*' : req.airportCodes;
    obj[name] = airportCodes;
  }
  return obj;
};
