import React, { useContext, type ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Tabs, Tab } from '@mui/material';
import { TravelContext } from '../store/TravelContext';
import { TravelConfiguration } from '../components/TravelConfiguration';
import { TravelDetails } from '../components/TravelDetails';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return <>{value === index && <>{children}</>}</>;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const selectedTabStyles = {
  '&.Mui-selected': {
    color: '#F69A19',
    border: '1px solid #F69A19',
    fontSize: { lg: '17px', sm: '17px', xs: '16px' },
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: 0.4,
    textTransform: 'none',
    background: '#f6ede2',
  },
};

const unselectedTabStyles = {
  '&:not(.Mui-selected)': {
    textTransform: 'none',
    background: '#e9e9e9',
    border: '1px solid #c7c7c7',
    fontFamily: 'Roboto',
    fontSize: { lg: '17px', sm: '17px', xs: '16px' },
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#242424',
  },
};

export const AdminPage = (): ReactElement<React.FC> => {
  const [value, setValue] = React.useState(0);
  const travelCtx = useContext(TravelContext);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid
      container
      xs={15}
      sm={13}
      lg={8}
      mdOffset={2}
      width='100%'
      sx={{ backgroundColor: '#FFF' }}
      data-testid='AdminPageContainer'
    >
      <Grid width='100%' sx={{ bgcolor: '#f5f5f5' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='curriculum tabs'
          TabIndicatorProps={{ hidden: true }}
          sx={{ textTransform: 'none' }}
        >
          <Tab
            label='TRAVELLER DETAILS'
            {...a11yProps(0)}
            sx={{
              mr: '0.5%',
              ...selectedTabStyles,
              ...unselectedTabStyles,
            }}
          />
          <Tab
            label='CONFIGURATION'
            {...a11yProps(1)}
            sx={{
              ...selectedTabStyles,
              ...unselectedTabStyles,
            }}
            disabled={!travelCtx.isSuperAdmin}
          />
        </Tabs>
      </Grid>
      <TabPanel value={value} index={0}>
        <TravelDetails />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TravelConfiguration />
      </TabPanel>
    </Grid>
  );
};
