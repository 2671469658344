import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as LillyLogo } from '../assets/lilly.svg';

export const Footer = () => {
  return (
    <React.Fragment>
      <Toolbar
        variant='dense'
        component='footer'
        sx={{
          backgroundColor: '#E9E9E9',
          height: { sm: '2rem', md: '4rem' },
          marginTop: 'auto',
        }}
      >
        <Typography sx={{ flexGrow: 1, fontSize: '0.75rem', textDecoration: 'none' }}>
          {`Copyright © ${new Date().getFullYear()} Lilly. All rights reserved.`}
        </Typography>
        <Stack direction='row' spacing={1} justifyContent={'flex-end'}>
          <Typography
            sx={{
              fontSize: '0.75rem',
              alignSelf: 'center',
              textDecoration: 'none',
            }}
          >
            Powered by:
          </Typography>
          <SvgIcon
            component={LillyLogo}
            inheritViewBox
            sx={{ fontSize: { xs: '2rem', md: '4rem' } }}
          />
        </Stack>
      </Toolbar>
    </React.Fragment>
  );
};
