import React, { type ReactElement } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface Props {
  message: string;
  type: string;
  open: boolean;
  handleClose: () => void;
}
export const Toast = (props: Props): ReactElement<React.FC> => {
  return (
    <div>
      <Snackbar
        data-testid='ToastContainer'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.open}
        autoHideDuration={2000}
        onClose={props.handleClose}
      >
        <Alert
          elevation={6}
          severity={props.type === 'error' ? 'error' : 'success'}
          onClose={props.handleClose}
          sx={{ whiteSpace: 'pre-line', borderColor: 'severity' }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
