/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Modal, Box, Typography, Button, Stack } from '@mui/material';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  width: { md: '20rem', sm: '20rem', xs: '13rem' },
  bgcolor: 'background.paper',
  borderRadius: '3px',
  boxShadow: 24,
  p: 4,
};

export const ConfirmationModal = (props: any): ReactElement<React.FC> => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      style={{ backdropFilter: 'blur(1px)', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <Box sx={style}>
        <Grid container direction='column'>
          <Grid container direction='row' spacing={1}>
            <Grid container xs={12}>
              <Typography sx={{ fontWeight: 500 }}>Are you sure want to delete?</Typography>
            </Grid>
            <Grid container xs={12} direction='row-reverse' sx={{ mt: '10px' }}>
              <Stack justifyContent='space-between' sx={{ px: '1.5%' }}>
                <Stack direction='row' spacing={1}>
                  <Button
                    data-testid='b2'
                    color='primary'
                    variant='outlined'
                    onClick={props.handleDeleteConfirmation}
                    sx={{
                      fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                      bgcolor: '#F5FBFE',
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    data-testid='b1'
                    color='secondary'
                    variant='outlined'
                    onClick={props.onClose}
                    sx={{
                      fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                      bgcolor: '#f1f1f1',
                    }}
                  >
                    NO
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
