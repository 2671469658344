import React from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import HRGBanner from '../assets/banner.png';
import Link from '@mui/material/Link';

export const DuringTravel = () => {
  return (
    <React.Fragment>
      <Stack justifyContent={'center'}>
        <Stack sx={{ mx: 3 }}>
          <Typography
            sx={{
              fontSize: '1.10rem',
              fontWeight: 'bold',
            }}
          >
            Travel Instructions
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <Stack direction={{ xs: 'column', md: 'row', sm: 'column' }}>
            <Stack sx={{ my: '1rem', pt: '1rem' }} spacing={1}>
              <Typography display='inline'>
                1. Follow the guidance on the&nbsp;
                <Link
                  href='https://now.lilly.com/landing/global-globalsecuritymenuoverview/global-guidance-for-traveling-with-lilly-electronic-devices'
                  target='_blank'
                  rel='noreferrer noopener'
                  color='#00a1de'
                >
                  Traveling with Lilly Electronic Devices
                </Link>
                &nbsp;page on LillyNow.
              </Typography>
              <Typography display='inline'>
                2. Report a Protect Lilly Concern to&nbsp;
                <Link
                  href='https://cyber.lilly.com'
                  target='_blank'
                  rel='noreferrer noopener'
                  color='#00a1de'
                >
                  https://cyber.lilly.com
                </Link>
                &nbsp;or&nbsp;
                <Link
                  href='mailto:cyber@lilly.com'
                  target='_blank'
                  rel='noreferrer noopener'
                  color='#00a1de'
                >
                  cyber@lilly.com
                </Link>
              </Typography>
              <Typography display='inline'>
                3. If during travel or in any situation where your Lilly laptop or mobile device was
                not in your possession and/or you suspect it may have been tampered with take the
                steps below:
              </Typography>
              <Typography sx={{ pl: '2%' }}>
                <li>Do not connect the device to the Lilly network.</li>
                <li>Turn off the device.</li>
                <li>Call the Lilly IT Service Desk number that you saved during pre-travel.</li>
                <li>Report the incident to your supervisor.</li>
              </Typography>
            </Stack>
            <Stack
              sx={{
                mt: '2rem',
                width: { sm: '70%' },
                alignItems: { sm: 'end' },
              }}
            >
              <img src={HRGBanner} alt='banner' />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
