/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type ReactElement } from 'react';
import { Box, Modal, Divider, Typography, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as flightLogo } from '../assets/flight.svg';
import { ReactComponent as calenderLogo } from '../assets/calender.svg';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import { ConvertDateUtil } from '../utils/ConvertDateUtil';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  width: { md: '40rem', sm: '40rem', xs: '18rem' },
  bgcolor: 'background.paper',
  borderRadius: '3px',
  boxShadow: 24,
  p: 4,
};
interface Props {
  travelDetails: {
    traveler_name: string;
    email_address: string;
    origin_country?: string;
    destination_country?: string;
    travel_start_date: string;
    travel_end_date: string;
    stage: string;
    extra_details: any;
    ritm_number?: string;
    ritm_status?: string;
    panel1: boolean;
  };
  open: boolean;
  onClose: () => void;
}
const preTravelStages = [
  {
    label: 'Pre-Travel Email Has Been Sent',
  },
  {
    label: 'Reminder Pre-Travel Email Has Been Sent',
  },
];
const postTravelStages = [
  {
    label: 'Travel End Date Verification Email Has Been Sent',
  },
  {
    label: 'Post-Travel Email Has Been Sent',
  },
  {
    label: 'Reminder Post-Travel Email Has Been Sent',
  },
];
const QontoStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 15,
  height: 15,
  display: 'flex',
  ...(ownerState.completed && {
    backgroundColor: '#45BA7F',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'white',
    width: 13,
    height: 13,
  },
}));
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed && <Check className='QontoStepIcon-completedIcon' />}
    </QontoStepIconRoot>
  );
}
function createCheckArrayForPreTravel(data: any) {
  const preArray = [-1];
  if (data?.notification_30) {
    preArray.push(1);
  }
  if (data?.notification_created) {
    preArray.push(0);
  }
  return preArray;
}
function createCheckArrayForPostTravel(data: any) {
  const postArray = [-1];
  if (data?.notification_travel_end_2) {
    postArray.push(2);
  }
  if (data?.notification_travel_end_0) {
    postArray.push(1);
  }
  if (data?.notification_travel_end_30) {
    postArray.push(0);
  }
  return postArray;
}
export const TravelDetailsModel = (props: Props): ReactElement<React.FC> => {
  const post_travel_stage = createCheckArrayForPostTravel(props.travelDetails.extra_details);
  const pre_travel_stage = createCheckArrayForPreTravel(props.travelDetails.extra_details);
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        hideBackdrop={true}
        style={{ backdropFilter: 'blur(1px)', backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <Box sx={style}>
          <Grid container direction='column'>
            <Grid container direction='row' spacing={1}>
              <Grid container xs={12} spacing={1}>
                <Grid xs={11}>
                  <Typography variant='h6'>{props.travelDetails.traveler_name}</Typography>
                  <Typography variant='body2' sx={{ color: '#6B6B6B' }}>
                    {props.travelDetails.email_address}
                  </Typography>
                </Grid>
                <Grid xs={1}>
                  <CancelOutlinedIcon
                    color='primary'
                    onClick={props.onClose}
                    sx={{ fontSize: { sm: '2rem' }, cursor: 'pointer' }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sx={{ color: '#6B6B6B', mt: '5px' }}>
                <Stack direction='row' spacing={1}>
                  {props.travelDetails.panel1 ? (
                    <SvgIcon
                      component={flightLogo}
                      sx={{
                        height: { xs: '0.85rem', sm: '1rem', md: '1rem' },
                        pt: '0.25rem',
                      }}
                    />
                  ) : (
                    <ShoppingCartOutlinedIcon
                      sx={{ color: 'black', fontSize: { sm: '1.3rem', xs: '1.2rem' } }}
                    />
                  )}
                  <Typography
                    sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' }, fontWeight: 'bold' }}
                  >
                    {props.travelDetails.panel1 ? 'Origin' : 'RITM'} -
                  </Typography>
                  <Typography sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' } }}>
                    {props.travelDetails.panel1
                      ? props.travelDetails.origin_country
                      : props.travelDetails.ritm_number}
                  </Typography>
                  <Divider orientation='vertical' />
                  <Typography
                    sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' }, fontWeight: 'bold' }}
                  >
                    {props.travelDetails.panel1 ? 'Destination' : 'RITM Status'} -
                  </Typography>
                  <Typography sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' } }}>
                    {props.travelDetails.panel1
                      ? props.travelDetails.destination_country
                      : props.travelDetails.ritm_status}
                  </Typography>
                </Stack>
              </Grid>
              <Grid container xs={12} sx={{ color: '#6B6B6B' }}>
                <Stack direction='row' spacing={1}>
                  <SvgIcon
                    component={calenderLogo}
                    sx={{
                      height: { xs: '0.85rem', sm: '1rem', md: '1rem' },
                      pt: '0.25rem',
                      fill: 'white',
                    }}
                  />
                  <Typography
                    sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' }, fontWeight: 'bold' }}
                  >
                    Travel Start -
                  </Typography>
                  <Typography sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' } }}>
                    {ConvertDateUtil(props.travelDetails.travel_start_date)}
                  </Typography>
                  <Divider orientation='vertical' />
                  <Typography
                    sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' }, fontWeight: 'bold' }}
                  >
                    Travel End -
                  </Typography>
                  <Typography sx={{ fontSize: { xs: '0.6rem', sm: '1rem', ms: '1rem' } }}>
                    {ConvertDateUtil(props.travelDetails.travel_end_date)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={12} sx={{ my: '0.5rem' }}>
                <Divider />
              </Grid>
            </Grid>
            {props.travelDetails.panel1 && (
              <Grid container direction='row'>
                <Stack direction='column' spacing={1}>
                  <Typography variant='h6'>Pre Travel</Typography>
                  <Stepper
                    orientation='vertical'
                    sx={{ '.MuiStepConnector-vertical': { ml: '8px' } }}
                  >
                    {preTravelStages.map((stage, index) => (
                      <Step
                        key={stage.label}
                        completed={pre_travel_stage.includes(index)}
                        disabled={!pre_travel_stage.includes(index)}
                        sx={{ '.MuiStepLabel-vertical': { p: '0' } }}
                      >
                        <StepLabel color='inherit' StepIconComponent={QontoStepIcon}>
                          <Typography
                            sx={{
                              fontSize: { xs: '0.5rem ', sm: '0.8rem' },
                              fontWeight: 500,
                            }}
                          >
                            {stage.label}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              </Grid>
            )}
            {!props.travelDetails.panel1 && (
              <Grid container direction='row'>
                <Grid xs={12}>
                  <Stack direction='column'>
                    <Typography variant='h6'>Post Travel</Typography>
                    <Stepper
                      orientation='vertical'
                      sx={{ '.MuiStepConnector-vertical': { ml: '8px' } }}
                    >
                      {postTravelStages.map((stage, index) => (
                        <Step
                          key={stage.label}
                          completed={post_travel_stage.includes(index)}
                          disabled={!post_travel_stage.includes(index)}
                          sx={{ '.MuiStepLabel-vertical': { p: '0' } }}
                        >
                          <StepLabel color='inherit' StepIconComponent={QontoStepIcon}>
                            <Typography
                              sx={{
                                fontSize: { xs: '0.5rem ', sm: '0.8rem' },
                                fontWeight: 500,
                              }}
                            >
                              {stage.label}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
