/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, type ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import { TravelHistory } from '../components/TravelHistory';
import { PostTravelHistory } from './PostTravelHistory';
import { TravelContext } from '../store/TravelContext';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { writeFile, utils } from 'xlsx';
import { FormatPreTravelRecords, FormatPostTravelRecords } from '../utils/FormatRecordsUtil';
import { ConvertDateUtil } from '../utils/ConvertDateUtil';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const TravelDetails = (): ReactElement<React.FC> => {
  const { instance, accounts } = useMsal();
  const [disabled, setDisabled] = React.useState(false);
  const [expanded, setExpanded] = React.useState<string>('panel1');
  const [eventData, setEventData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : panel === 'panel1' ? 'panel2' : 'panel1');
  };
  const travelCtx = useContext(TravelContext);
  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };
  /* istanbul ignore next */
  const handleExportAll = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const preResponse: any = await axios
        .get(`${apiUrl}/admin/getAllPreTravelRecords`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (preResponse.data.status !== 200) {
        throw Error(preResponse.data.msg);
      }
      const temp = [];
      for (let i = 0; i < preResponse.data.msg.length; i++) {
        const travelData = {
          ...preResponse.data.msg[i],
          travel_start_date: ConvertDateUtil(preResponse.data.msg[i].travel_start_date),
          travel_end_date: ConvertDateUtil(preResponse.data.msg[i].travel_end_date),
        };
        temp.push(travelData);
      }
      const preData = FormatPreTravelRecords(temp);
      const postResponse: any = await axios
        .get(`${apiUrl}/admin/getAllPostTravelRecords`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (postResponse.data.status !== 200) {
        throw Error(postResponse.data.msg);
      }
      const data = [];
      for (let i = 0; i < postResponse.data.msg.length; i++) {
        const travelData = {
          ...postResponse.data.msg[i],
          travel_start_date:
            postResponse.data.msg[i].travel_start_date &&
            ConvertDateUtil(postResponse.data.msg[i].travel_start_date),
          travel_end_date:
            postResponse.data.msg[i].travel_end_date &&
            ConvertDateUtil(postResponse.data.msg[i].travel_end_date),
          runbookIncident: postResponse.data.msg[i].password_reset_incidents?.runbookIncident || '',
          securityIncident:
            postResponse.data.msg[i].password_reset_incidents?.securityIncident || '',
          passwordStatus: postResponse.data.msg[i].password_reset_status || 'Not Available',
        };
        data.push(travelData);
      }
      const postData = FormatPostTravelRecords(data);
      const wb = utils.book_new();

      const data1 = [
        [
          'Traveler Name',
          'Email',
          'Origin Place',
          'Destination',
          'Travel Start Date',
          'Travel End Date',
        ],
        ...preData.map(Object.values),
      ];
      const ws1 = utils.aoa_to_sheet(data1);
      utils.book_append_sheet(wb, ws1, 'pre_travel');

      const data2 = [
        [
          'Traveler Name',
          'Email',
          'Travel Start Date',
          'Travel End Date',
          'ServiceNow Request Number',
          'ServiceNow Request Status',
          'Incident for Investigation',
          'Incident for Resolution',
          'Password Reset Status',
        ],
        ...postData.map(Object.values),
      ];
      const ws2 = utils.aoa_to_sheet(data2);
      utils.book_append_sheet(wb, ws2, 'post_travel');

      writeFile(wb, 'travel_records.xlsx');
      setIsLoading(false);
    } catch (err) {
      console.log('Error in export all', err);
      setIsLoading(false);
    }
  };
  const handleExport = () => {
    const data = {
      active: expanded,
      export: true,
      supress: false,
    };
    setEventData(data);
  };
  const handleSave = () => {
    const data = {
      active: expanded,
      export: false,
      supress: true,
    };
    setEventData(data);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 2000);
  };

  return (
    <Grid width='100%' height='100%' justifyContent='flex-start' sx={{ p: '5px' }}>
      <Stack spacing={1}>
        <Stack
          direction='row-reverse'
          justifyContent={{ xs: 'center', sm: 'space-between', md: 'space-between' }}
          sx={{ px: '2%' }}
        >
          <Stack direction='row' spacing={1}>
            <Button
              data-testid='b2'
              variant='outlined'
              color='inherit'
              sx={{
                color: '#2E7D32',
                fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                bgcolor: '#F7FAF7',
              }}
              onClick={handleExportAll}
              disabled={isLoading}
            >
              {isLoading ? 'Exporting...' : 'Export All'}
            </Button>
            <Button
              data-testid='b1'
              color='primary'
              variant='outlined'
              sx={{
                fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                bgcolor: '#F5FBFE',
              }}
              onClick={handleSave}
              disabled={disabled || !travelCtx.isSuperAdmin}
            >
              Save
            </Button>
            <Button
              data-testid='b2'
              color='primary'
              variant='outlined'
              sx={{
                fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                bgcolor: '#F5FBFE',
              }}
              onClick={handleExport}
            >
              Export ({' '}
              {expanded === 'panel1' ? travelCtx.preTravelCount : travelCtx.postTravelCount} )
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={0.5} sx={{ mx: '5px', my: '5px' }}>
        <InfoOutlinedIcon sx={{ fontSize: '1.15rem' }} />
        <Typography color='secondary' variant='subtitle2'>
          Displaying information from the previous one year.
        </Typography>
      </Stack>
      <Stack sx={{ mx: '5px' }}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownIcon sx={{ color: expanded === 'panel1' ? '#F69A19' : '' }} />
            }
            aria-controls='panel1bh-content'
            id='panel1bh-header'
            sx={{ bgcolor: expanded === 'panel1' ? '#f6ede2' : '' }}
          >
            <Typography
              sx={{
                fontWeight: expanded === 'panel1' ? 'bold' : '',
                color: expanded === 'panel1' ? '#F69A19' : '',
              }}
            >
              Pre Travel
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TravelHistory activepanel={eventData} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownIcon sx={{ color: expanded === 'panel2' ? '#F69A19' : '' }} />
            }
            aria-controls='panel2bh-content'
            id='panel2bh-header'
            sx={{ bgcolor: expanded === 'panel2' ? '#f6ede2' : '' }}
          >
            <Typography
              sx={{
                fontWeight: expanded === 'panel2' ? 'bold' : '',
                color: expanded === 'panel2' ? '#F69A19' : '',
              }}
            >
              Post Travel
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PostTravelHistory activepanel={eventData} />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Grid>
  );
};
