import React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';

export const Layout = () => {
  return (
    <Box
      bgcolor='background.default'
      color='text.primary'
      sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      data-testid='LayoutContainer'
    >
      <Header />
      <Box
        component='main'
        flex='1'
        sx={{
          padding: { xs: '1rem', sm: '1.5rem' },
          display: 'flex',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};
