export const ConvertDateUtil = (date: string) => {
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const TravelDate = date.split('-');
  if (TravelDate.length === 3) {
    return `${TravelDate[2]}-${month[Number(TravelDate[1]) - 1]}-${TravelDate[0]}`;
  }
  return '';
};
