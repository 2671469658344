/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const TravelContext = React.createContext<{
  travelRecords: any;
  setTravelRecords: any;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  setAdmin: any;
  setSuperAdmin: any;
  getRouteId: number;
  setRouteId: any;
  preTravelCount: number;
  setPreCount: any;
  postTravelCount: number;
  setPostCount: any;
}>({
  isAdmin: false,
  isSuperAdmin: false,
  travelRecords: {},
  getRouteId: 0,
  preTravelCount: 0,
  postTravelCount: 0,
  setTravelRecords: (item: any) => {
    item;
  },
  setAdmin: (flag: boolean) => {
    flag;
  },
  setSuperAdmin: (flag: boolean) => {
    flag;
  },
  setRouteId: (id: number) => {
    id;
  },
  setPreCount: (count: number) => {
    count;
  },
  setPostCount: (count: number) => {
    count;
  },
});
