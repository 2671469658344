/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useContext, type ReactElement, useMemo, useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
} from 'material-react-table';
import { Stack, CircularProgress, Button } from '@mui/material';
import { TravelDetailsModel } from './TravelDetailsModel';
import axios from 'axios';
import { ConvertDateUtil } from '../utils/ConvertDateUtil';
import { GetStageUtil } from '../utils/GetStageUtil';
import { TravelContext } from '../store/TravelContext';
import { useMsal } from '@azure/msal-react';
import { utils } from 'xlsx';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { Toast } from './Toast';
import { FormatPostTravelRecords } from '../utils/FormatRecordsUtil';

export type Travel = {
  id: string;
  traveler_name: string;
  email_address: string;
  travel_start_date: string;
  travel_end_date: string;
  stage: string;
  ritm_number: string;
  ritm_status: string;
  extra_details: any;
  suppress: boolean;
};

export const PostTravelHistory = (props: any): ReactElement<React.FC> => {
  const tableInstanceRef: any = useRef(null);
  let context;
  const [open, setOpen] = React.useState(false);
  const { instance, accounts } = useMsal();
  const [travelData, setTravelData] = React.useState({
    traveler_name: '',
    email_address: '',
    travel_start_date: '',
    travel_end_date: '',
    stage: '',
    ritm_number: '',
    ritm_status: '',
    extra_details: {},
    panel1: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<Travel[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [toastState, setToastState] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(undefined);
  const travelCtx = useContext(TravelContext);
  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };
  const getTravelRecords = async (): Promise<void> => {
    try {
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const response: any = await axios
        .get(`${apiUrl}/admin/postTravelDetails/getAllRecords`, {
          headers: {
            Authorization: `Bearer ${token?.idToken}`,
            graph_token: token?.accessToken,
            'Content-Type': 'application/json',
          },
        })
        .then((responseBody) => responseBody);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      const data = [];
      for (let i = 0; i < response.data.msg.length; i++) {
        const stageDetails = GetStageUtil(
          response.data.msg[i].travel_start_date,
          response.data.msg[i].travel_end_date
        );
        const travelData = {
          ...response.data.msg[i],
          stage: stageDetails.name,
          stageValue: stageDetails.stage,
          runbookIncident: response.data.msg[i].password_reset_incidents?.runbookIncident || '',
          securityIncident: response.data.msg[i].password_reset_incidents?.securityIncident || '',
          passwordStatus: response.data.msg[i].password_reset_status || 'Not Available',
        };
        data.push(travelData);
      }
      travelCtx.setPostCount(data.length);
      data.sort((a: any, b: any) => a.stageValue - b.stageValue);
      const checkedTravel = data
        .filter((travel) => travel.suppress === true)
        .map((data) => data.id)
        .reduce((acc, elem) => {
          acc[elem] = true;
          return acc;
        }, {});
      setRows(data);
      setRowSelection((prev) => ({ ...prev, ...checkedTravel }));
      setIsLoading(false);
    } catch (err) {
      console.log('Error', err);
      setIsLoading(false);
    }
  };
  const getCheckedUnchecked = () => {
    const rowSelectedTravelId = Object.keys(rowSelection);
    const uncheckedTravelIds: number[] = [];
    rows.map((travel) => {
      if (!rowSelectedTravelId.includes('' + travel.id)) {
        uncheckedTravelIds.push(Number(travel.id));
      }
    });
    return { rowSelectedTravelId, uncheckedTravelIds };
  };
  useEffect((): void => {
    (async (): Promise<void> => {
      await getTravelRecords();
    })();
  }, []);
  useEffect((): void => {
    (async (): Promise<void> => {
      if (props.activepanel.active === 'panel2') {
        if (props.activepanel?.export) {
          handleDownload();
        }
        if (props.activepanel?.supress) {
          const result = getCheckedUnchecked();
          if (result.rowSelectedTravelId.length === 0 && result.uncheckedTravelIds.length === 0) {
            setToastState({
              open: true,
              message: 'No checkbox checked',
              type: 'error',
            });
          } else {
            await handleSave();
          }
        }
      }
    })();
  }, [props.activepanel]);
  /* istanbul ignore next */
  const handleOnclick = (data: Travel) => {
    const record = {
      traveler_name: data.traveler_name,
      email_address: data.email_address,
      travel_start_date: data.travel_start_date,
      travel_end_date: data.travel_end_date,
      stage: data.stage,
      ritm_number: data.ritm_number,
      ritm_status: data.ritm_status,
      extra_details: data.extra_details,
      panel1: false,
    };
    setTravelData({ ...record });
    setOpen(true);
  };
  /* istanbul ignore next */
  const handleDownload = () => {
    let tableRows: any = [];
    if (
      tableInstanceRef?.current &&
      typeof tableInstanceRef?.current?.getFilteredRowModel === 'function'
    ) {
      tableInstanceRef.current.getFilteredRowModel().flatRows.forEach((element: any) => {
        tableRows.push(element.original);
      });
    } else {
      tableRows = rows;
    }
    const csvData = FormatPostTravelRecords(tableRows);
    const data = csvData.map((row) => {
      return {
        'Traveler Name': row.traveler_name,
        Email: row.email_address,
        'Travel Start Date': ConvertDateUtil(row.travel_start_date),
        'Travel End Date': ConvertDateUtil(row.travel_end_date),
        'ServiceNow Request Number': row.ritm_number,
        'ServiceNow Request Status': row.ritm_status,
        'Incident for Investigation': row.runbook_incident,
        'Incident for Resolution': row.security_incident,
        'Password Reset Status': row.passwordStatus,
      };
    });
    const ws = utils.json_to_sheet(data);
    const csv = utils.sheet_to_csv(ws);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a download link and click it
    const link = document.createElement('a');
    const downloadUrl = URL.createObjectURL(blob);
    link.href = downloadUrl;
    link.download = 'post_travel_records.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /* istanbul ignore next */
  const handleSave = async () => {
    try {
      const result = getCheckedUnchecked();
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const config = {
        method: 'post',
        url: `${apiUrl}/sa/postTravelDetails/suppressNotification`,
        headers: {
          Authorization: `Bearer ${token?.idToken}`,
          'Content-Type': 'application/json',
          graph_token: token?.accessToken,
        },
        data: {
          idsToEnableNotification: result.rowSelectedTravelId.map(Number),
          idsToDisableNotification: result.uncheckedTravelIds,
        },
      };
      const response = await axios(config);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      await getTravelRecords();
      setToastState({
        open: true,
        message: 'Suppress Notification and Table Updated Successfully',
        type: 'success',
      });
    } catch (error) {
      setToastState({
        open: true,
        message: 'Failed to Update Suppress Notification',
        type: 'error',
      });
      console.log('Error while saving suppress notification', error);
    }
  };
  useEffect(() => {
    let tableRows: any = [];
    if (
      tableInstanceRef?.current &&
      typeof tableInstanceRef?.current?.getFilteredRowModel === 'function'
    ) {
      tableInstanceRef.current.getFilteredRowModel().flatRows.forEach((element: any) => {
        tableRows.push(element.original);
      });
    } else {
      tableRows = rows;
    }
    travelCtx.setPostCount(tableRows.length);
  }, [columnFilters]);
  useEffect(() => {
    let tableRows: any = [];
    if (
      tableInstanceRef?.current &&
      typeof tableInstanceRef?.current?.getFilteredRowModel === 'function'
    ) {
      tableInstanceRef.current.getFilteredRowModel().flatRows.forEach((element: any) => {
        tableRows.push(element.original);
      });
    } else {
      tableRows = rows;
    }
    travelCtx.setPostCount(tableRows.length);
  }, [globalFilter]);

  const columns = useMemo<MRT_ColumnDef<Travel>[]>(
    () => [
      {
        accessorKey: 'traveler_name',
        header: 'Name',
      },
      {
        accessorKey: 'email_address',
        header: 'Email Id',
      },
      {
        accessorFn: (originalRow) => ConvertDateUtil(originalRow.travel_start_date),
        accessorKey: 'travel_start_date',
        header: 'Travel Start Date',
        size: 10,
        Cell: ({ row }) => ConvertDateUtil(row.original.travel_start_date),
      },
      {
        accessorFn: (originalRow) => ConvertDateUtil(originalRow.travel_end_date),
        accessorKey: 'travel_end_date',
        header: 'Travel End Date',
        size: 10,
        Cell: ({ row }) => ConvertDateUtil(row.original.travel_end_date),
      },
      {
        accessorKey: 'ritm_number',
        header: 'ServiceNow Request Number',
      },
      {
        accessorKey: 'ritm_status',
        header: 'ServiceNow Request Status',
        filterVariant: 'select',
        filterSelectOptions: [
          'OPEN',
          'WORK IN PROGRESS',
          'CLOSED COMPLETED',
          'CLOSED CANCELLED',
          'CLOSED SKIPPED',
          'CLOSED INCOMPLETE',
        ],
      },
      {
        accessorKey: 'passwordStatus',
        header: 'Password Reset Status',
        filterVariant: 'select',
        filterSelectOptions: [
          'Completed by System',
          'Pending User',
          'Incidents Created',
          'Completed by User',
          'Pending System',
          'Not Available',
        ],
      },
      {
        accessorKey: 'runbookIncident',
        header: 'Incident for Investigation',
      },
      {
        accessorKey: 'securityIncident',
        header: 'Incident for Resolution',
      },
      {
        accessor: 'action',
        header: 'Action',
        size: 10,
        Cell: ({ row }) => (
          <Button
            color='inherit'
            variant='outlined'
            sx={{
              fontSize: { xs: '0.5rem', sm: '0.75rem' },
              color: '#F69A19',
            }}
            onClick={() => handleOnclick(row.original)}
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );
  if (isLoading) {
    context = (
      <Grid
        data-testid='Spinner'
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        height='50vh'
      >
        <CircularProgress size='6rem' />
      </Grid>
    );
  } else {
    context = (
      <Grid container height='100%' justifyContent='flex-start' sx={{ p: '5px' }}>
        <Grid width='100%'>
          <Stack spacing={1}>
            <Stack>
              <MaterialReactTable
                tableInstanceRef={tableInstanceRef}
                muiTablePaperProps={{
                  sx: {
                    px: '1rem',
                    boxShadow: '0',
                  },
                }}
                defaultColumn={{
                  size: 150, //default size is usually 180
                }}
                enableSelectAll={false}
                getRowId={(row) => row.id}
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                enableRowSelection={(row) => travelCtx.isSuperAdmin}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection, columnFilters, globalFilter }}
                columns={columns}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                enablePinning
                initialState={{ columnPinning: { right: ['Action'] } }}
                data={rows}
                enableColumnActions={false}
                enableTopToolbar
                muiTableBodyRowProps={{ hover: false }}
                globalFilterModeOptions={['fuzzy', 'contains']}
                displayColumnDefOptions={{
                  'mrt-row-select': {
                    header: 'Suppress',
                  },
                }}
                positionToolbarAlertBanner='none'
                muiTableHeadCellProps={{
                  sx: {
                    bgcolor: '#f1f1f1',
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    background: 'white',
                  },
                }}
              />
            </Stack>
          </Stack>
          {open && (
            <TravelDetailsModel
              travelDetails={travelData}
              open={open}
              onClose={() => setOpen(false)}
            />
          )}
        </Grid>
        {toastState.open && (
          <Toast
            open={toastState.open}
            handleClose={() => setToastState({ ...toastState, open: false })}
            message={toastState.message}
            type={toastState.type}
          />
        )}
      </Grid>
    );
  }
  return context;
};
