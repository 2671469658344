/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { TravelContext } from './TravelContext';

export const TravelProvider = (props: any) => {
  const [state, setState] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [id, setId] = useState(0);
  const [preTravelCount, setPreTravelCount] = useState(0);
  const [postTravelCount, setPostTravelCount] = useState(0);
  const cartContext: any = {
    isAdmin: isAdmin,
    isSuperAdmin: isSuperAdmin,
    travelRecords: state,
    getRouteId: id,
    preTravelCount: preTravelCount,
    postTravelCount: postTravelCount,
    setTravelRecords: (item: any) => {
      setState({ ...item });
    },
    setAdmin: (flag: boolean) => {
      setIsAdmin(flag);
    },
    setSuperAdmin: (flag: boolean) => {
      setSuperAdmin(flag);
    },
    setRouteId: (routeId: number) => {
      setId(routeId);
    },
    setPreCount: (routeId: number) => {
      setPreTravelCount(routeId);
    },
    setPostCount: (routeId: number) => {
      setPostTravelCount(routeId);
    },
  };
  // eslint-disable-next-line react/react-in-jsx-scope
  return <TravelContext.Provider value={cartContext}>{props.children}</TravelContext.Provider>;
};
