import React, { useEffect, useContext, type ReactElement } from 'react';
import { TravelContext } from '../store/TravelContext';
import { useNavigate, useParams } from 'react-router-dom';

export const TravelByIdPage = (): ReactElement<React.FC> => {
  const { id } = useParams();
  const navigate = useNavigate();
  const travelCtx = useContext(TravelContext);
  useEffect(() => {
    if (id !== undefined && Number(id)) {
      travelCtx.setRouteId(Number(id));
      navigate('/');
    }
    // else {
    //   navigate('/404');
    // }
  }, []);
  return <></>;
};
