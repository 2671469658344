/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from 'react';
import { Divider, Typography, Stack, Checkbox, Tooltip, CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { TravelContext } from '../store/TravelContext';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import { Toast } from './Toast';

export default function PreTravel() {
  const preTravelRecord = useContext(TravelContext);
  const [toastState, setToastState] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [ritm, setRitm] = useState({ ritm_no: '', status: '' });
  const [isLoanerRequired, setIsLoanerRequired] = useState('');
  const [ritmRefreshLoader, setRitmRefreshLoader] = useState(false);
  const { instance, accounts } = useMsal();
  const [localCheck, setLocalCheck] = useState(false);
  const [localCheckForNo, setLocalCheckForNo] = useState(false);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    const loaner_device =
      preTravelRecord.travelRecords.loaner_device === null
        ? ''
        : preTravelRecord.travelRecords.loaner_device
          ? 'yes'
          : 'no';
    setIsLoanerRequired(loaner_device);
    setChecked(loaner_device === 'no' ? true : false);
  }, [preTravelRecord]);
  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };
  const handleSubmit = async () => {
    const token = await getAccessToken();
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const config = {
      method: 'post',
      url: `${apiUrl}/updateLoanerDeviceById`,
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
        'Content-Type': 'application/json',
        graph_token: token?.accessToken,
      },
      data: {
        id: preTravelRecord.travelRecords.id,
        loaner_device: isLoanerRequired === 'yes',
      },
    };
    try {
      const response = await axios(config);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      if (isLoanerRequired === 'yes') setLocalCheck(true);
      if (isLoanerRequired === 'no') setLocalCheckForNo(true);
      setToastState({ open: true, message: 'Record successfully updated', type: 'success' });
    } catch (err) {
      setToastState({ open: true, message: 'Failed to Update', type: 'error' });
      console.log(err);
    }
  };
  /* istanbul ignore next */
  const checkRITMHandler = async () => {
    try {
      setRitmRefreshLoader(true);
      const token = await getAccessToken();
      const apiUrl = process.env.REACT_APP_API_URL as string;
      const config = {
        method: 'get',
        url: `${apiUrl}/updateRITMDetail/${preTravelRecord.travelRecords.id}/${accounts[0].idTokenClaims?.uid}`,
        headers: {
          Authorization: `Bearer ${token?.idToken}`,
          'Content-Type': 'application/json',
          graph_token: token?.accessToken,
        },
      };
      const responseRITM: any = await axios(config);
      if (responseRITM.data.status !== 200) {
        throw Error(responseRITM.data.msg);
      }
      config.url = `${apiUrl}/travelDetails/id/${preTravelRecord.travelRecords.id}`;
      const responseTravel: any = await axios(config);
      if (responseTravel.data.status !== 200) {
        throw Error(responseTravel.data.msg);
      }
      setRitm({
        ...ritm,
        ritm_no: responseTravel.data.msg[0].ritm_number,
        status: responseTravel.data.msg[0].extra_details?.ritm_status,
      });
      setRitmRefreshLoader(false);
      if (responseTravel.data.msg[0].extra_details?.ritm_status !== 'OPEN') {
        setToastState({
          open: true,
          message: 'No open RITM found.',
          type: 'success',
        });
      }
    } catch (err) {
      console.log('Error', err);
      setRitmRefreshLoader(false);
      setToastState({
        open: true,
        message: 'Failed to refresh or no RITM found. Please try again later.',
        type: 'error',
      });
    }
  };
  return (
    <React.Fragment>
      <Stack spacing={2} justifyContent={'center'} sx={{ mx: 3 }}>
        <Stack>
          <Stack sx={{ backgroundColor: '#f5f5f5', p: '1rem' }}>
            <Typography>
              To support {`Lilly's`} goals and objectives, we recognize that you may need to travel
              to other locations as part of your work responsibilities. This application will help
              guide you through the requirements when traveling to a higher-risk geography (HRG).
            </Typography>
            <Typography sx={{ my: '0.5rem' }}>
              Based on our records, you have booked travel to an HRG. To find the latest list of
              HRGs and for more information, visit the &nbsp;
              <Link
                href='https://now.lilly.com/page/global-protect-lilly-hrg-travel-requirements'
                target='_blank'
                rel='noreferrer noopener'
                color='#00a1de'
              >
                Protect Lilly Travel Requirements to Higher-Risk Geographies
              </Link>
              &nbsp;page on LillyNow.
            </Typography>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'medium' }}>
              Before traveling, here are the requirements to know:
            </Typography>
            <Typography>
              1. Understand what countries you are allowed to work in outside of your home country
              by reading the&nbsp;
              <Link
                href='https://now.lilly.com/page/global-working-flexibly-where-you-can-work'
                target='_blank'
                rel='noreferrer noopener'
                color='#00a1de'
              >
                Where You Can Work
              </Link>
              &nbsp;guidelines.
              <br />
              2. Do not take your Lilly-issued laptop, iPhone, iPad or other devices.
              <br />
              3. If you are allowed to work from the destination country and you require a device to
              complete your work while traveling, you must request a loaner device(s) at least two
              weeks prior to travel, using this&nbsp;
              <Link
                href={`${process.env.REACT_APP_SERVICENOW_URL}/ec?id=sc_cat_item&sys_id=86a18e021b60d4dce4d8dd7ebd4bcb2d`}
                target='_blank'
                rel='noreferrer noopener'
                color='#00a1de'
              >
                loaner device request form
              </Link>
              .<br />
              4. Write down the contact information for the local IT Service Desk in your area in
              the event network access is lost.
              <li style={{ paddingLeft: '1rem' }}>
                Global IT Service Desk numbers can be found&nbsp;
                <Link
                  href={`${process.env.REACT_APP_SERVICENOW_URL}/ess/kb_view.do?sys_kb_id=7c130c811bb3d550abbd33fe034bcb58&sysparm_stack=&sysparm_view=`}
                  target='_blank'
                  rel='noreferrer noopener'
                  color='#00a1de'
                >
                  here
                </Link>
                .
              </li>
            </Typography>
            <Typography sx={{ mt: '0.5rem' }}>
              Information Security recommends requesting a loaner iPhone and/or iPad for your
              travel. You should only request a loaner laptop if a loaner iPad or iPhone cannot meet
              your work needs during travel.
            </Typography>
          </Stack>
          <Divider sx={{ mt: 2 }} />
          <FormControl>
            <FormLabel sx={{ mt: 1, mb: 1 }} focused={false}>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                }}
              >
                Do you need a Lilly device(s) with you on this trip?
              </Typography>
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel
                control={
                  <Radio
                    checked={isLoanerRequired === 'yes'}
                    onChange={() => setIsLoanerRequired('yes')}
                    value='yes'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'Yes' }}
                    data-testid='r1'
                    disabled={
                      preTravelRecord.travelRecords.travel_stage.stage !== 0 ||
                      preTravelRecord.travelRecords.loaner_device ||
                      localCheck
                    }
                  />
                }
                label='Yes'
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={isLoanerRequired === 'no'}
                    onChange={() => setIsLoanerRequired('no')}
                    value='no'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'No' }}
                    disabled={
                      preTravelRecord.travelRecords.travel_stage.stage !== 0 ||
                      preTravelRecord.travelRecords.loaner_device ||
                      localCheck
                    }
                  />
                }
                label='No'
              />
            </RadioGroup>
          </FormControl>
          <Stack>
            {(preTravelRecord.travelRecords.loaner_device || localCheck) && (
              <Stack direction='row' spacing={1} mt={1}>
                <InsertLinkOutlinedIcon sx={{ color: '#00A1DE' }} />
                <Link
                  href={`${process.env.REACT_APP_SERVICENOW_URL}/ess/view_content_search.do?v=1&uri=com.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D86a18e021b60d4dce4d8dd7ebd4bcb2d%26sysparm_link_parent%3D84bd537135e7340014379b119de070f7%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4&sysparm_document_key=sc_cat_item,86a18e021b60d4dce4d8dd7ebd4bcb2d`}
                  target='_blank'
                  rel='noreferrer noopener'
                  sx={{ fontWeight: 'medium' }}
                >
                  Follow this link to request devices for international travel
                </Link>
              </Stack>
            )}
            {isLoanerRequired === 'no' && (
              <>
                <FormControlLabel
                  label='I acknowledge that I will not take any Lilly device into a Higher-Risk Geography. By acknowledging this, it will silence the Traveling in HRG reminders.'
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      disabled={
                        preTravelRecord.travelRecords.travel_stage.stage !== 0 ||
                        (preTravelRecord.travelRecords.loaner_device !== null &&
                          !preTravelRecord.travelRecords.loaner_device) ||
                        (localCheckForNo && isLoanerRequired === 'no')
                      }
                    />
                  }
                />
                <Typography color='secondary' variant='subtitle2' sx={{ fontStyle: 'italic' }}>
                  Please note: you may begin receiving these reminders again if a request for a
                  loaner device is detected.
                </Typography>
              </>
            )}
          </Stack>
          <Stack direction='row'>
            <Button
              data-testid='b1'
              color='primary'
              variant='contained'
              sx={{ my: '1rem', fontSize: { xs: 'small', sm: 'medium' } }}
              style={{ width: '5rem' }}
              onClick={handleSubmit}
              disabled={
                isLoanerRequired === '' ||
                preTravelRecord.travelRecords.travel_stage.stage !== 0 ||
                preTravelRecord.travelRecords.loaner_device ||
                (preTravelRecord.travelRecords.loaner_device !== null &&
                  !preTravelRecord.travelRecords.loaner_device &&
                  isLoanerRequired === 'no') ||
                (localCheckForNo && isLoanerRequired === 'no') ||
                localCheck ||
                (isLoanerRequired === 'no' && !checked)
              }
            >
              <Typography sx={{ color: 'white', fontWeight: 'medium' }}>Submit</Typography>
            </Button>
            {preTravelRecord.travelRecords.travel_stage.stage === 0 &&
              preTravelRecord.travelRecords.ritm_number === null &&
              (preTravelRecord.travelRecords.loaner_device || localCheck) &&
              ritm.ritm_no === '' && (
                <>
                  {!ritmRefreshLoader ? (
                    <Tooltip title='Refresh Ritm'>
                      <RefreshOutlinedIcon
                        data-testid='r2'
                        sx={{ m: '1rem', fontSize: '2rem', cursor: 'pointer', display: 'none' }}
                        onClick={checkRITMHandler}
                      />
                    </Tooltip>
                  ) : (
                    <CircularProgress size='1.5rem' sx={{ m: '1.5rem' }} />
                  )}
                </>
              )}
          </Stack>
          <Stack direction='row' spacing={0.5}>
            <InfoOutlinedIcon sx={{ fontSize: '1.15rem' }} />
            <Typography color='secondary' variant='subtitle2' sx={{ fontStyle: 'italic' }}>
              For more information about Lilly’s workforce privacy policy, please refer to your
              local workforce privacy notice.
            </Typography>
          </Stack>
        </Stack>
        {(preTravelRecord.travelRecords.loaner_device || localCheck) &&
          ((preTravelRecord.travelRecords.ritm_number &&
            preTravelRecord.travelRecords.extra_details?.ritm_status === 'OPEN') ||
            (ritm.ritm_no && ritm.status === 'OPEN')) && (
            <>
              <Divider sx={{ mt: 2 }} />
              <Stack justifyContent='center' alignItems='center' spacing={2}>
                <Stack direction='row' spacing={0.5}>
                  <CheckCircleSharpIcon sx={{ color: '#45BA7F' }} />
                  <Typography color='secondary'>
                    Device for international travel has been requested successfully.
                  </Typography>
                </Stack>
                <Typography variant='subtitle2'>
                  RITM NUMBER - {preTravelRecord.travelRecords.ritm_number || ritm.ritm_no}
                </Typography>
                <Button color='primary' variant='contained' sx={{ my: '1.5rem' }}>
                  <Link
                    href={`${process.env.REACT_APP_SERVICENOW_URL}/ess/my_tickets.do`}
                    target='_blank'
                    rel='noreferrer noopener'
                    color='#616161'
                    underline='none'
                  >
                    <Typography sx={{ color: 'white', fontWeight: 'medium' }}>
                      CHECK STATUS
                    </Typography>
                  </Link>
                </Button>
              </Stack>
            </>
          )}
      </Stack>
      {toastState.open && (
        <Toast
          open={toastState.open}
          handleClose={() => setToastState({ ...toastState, open: false })}
          message={toastState.message}
          type={toastState.type}
        />
      )}
    </React.Fragment>
  );
}
