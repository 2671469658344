/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, type ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  width: { md: '40rem', sm: '40rem', xs: '18rem' },
  bgcolor: 'background.paper',
  borderRadius: '3px',
  boxShadow: 24,
  p: 4,
};

export const AirportCodesModel = (props: any): ReactElement<React.FC> => {
  const [checked, setChecked] = React.useState(true);
  const [country, setCountry] = React.useState<string | null>(null);
  const [allCodes, setAllCodes] = React.useState([]);
  const [selectedCodes, setSelectedCodes] = React.useState([]);
  useEffect(() => {
    const selectCountry = props.airportCodes.filter((option: any) => option.name === country);
    if (country === null) {
      setSelectedCodes([]);
      setAllCodes([]);
    }
    if (selectCountry.length > 0) {
      setAllCodes(selectCountry[0].airportCodes);
      if (props.isEdit && props.selectedData) {
        setSelectedCodes(props.selectedData.airportCodes);
      } else {
        setSelectedCodes(selectCountry[0].airportCodes);
      }
    }
  }, [country]);
  useEffect(() => {
    const selectCountry = props.airportCodes.filter((option: any) => option.name === country);
    if (selectCountry.length > 0) {
      setChecked(selectCountry[0].airportCodes.length === selectedCodes.length || false);
    }
  }, [selectedCodes]);
  useEffect(() => {
    if (props.isEdit && props.selectedData) {
      setCountry(props.selectedData.name);
    }
  }, []);
  const handleAdd = () => {
    props.onAddOrEdit({ name: country, airportCodes: selectedCodes, allSelected: checked });
  };
  const flatPropsCountry = {
    options: props.airportCodes.map((option: any) => option.name),
  };
  const flatPropsCode = {
    options: allCodes,
  };
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      const selectCountry = props.airportCodes.filter((option: any) => option.name === country);
      if (selectCountry.length > 0) {
        setSelectedCodes(selectCountry[0].airportCodes);
      }
    } else {
      setSelectedCodes([]);
    }
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        hideBackdrop={true}
        style={{ backdropFilter: 'blur(1px)', backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <Box sx={style}>
          <Grid container direction='column'>
            <Grid container direction='row' spacing={1}>
              <Grid container xs={12} spacing={1}>
                <Grid xs={11}>
                  <Typography variant='h6'>
                    {props.isEdit ? 'Edit HRG Codes' : 'Add HRG Country & Codes'}
                  </Typography>
                </Grid>
                <Grid xs={1}>
                  <CancelOutlinedIcon
                    color='primary'
                    onClick={props.onClose}
                    sx={{ fontSize: { sm: '2rem' }, cursor: 'pointer' }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Stack width='100%' direction='column' spacing={2}>
                  <Typography sx={{ fontWeight: 500 }}>Country</Typography>
                  <Autocomplete
                    {...flatPropsCountry}
                    id='combo-box-demo'
                    size='small'
                    value={country}
                    disabled={props.isEdit}
                    sx={{ width: '100%' }}
                    onChange={(event: any, newValue: string | null) => {
                      setCountry(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label='Country Name' />}
                  />
                </Stack>
              </Grid>
              <Grid container xs={12} sx={{ my: '5px' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid='b1'
                        checked={checked}
                        onChange={handleChange}
                        disabled={country === null}
                      />
                    }
                    label='Select All Codes'
                  />
                </FormGroup>
              </Grid>
              <Grid container xs={12} sx={{ mb: '5px' }}>
                <Stack width='100%' direction='column' spacing={2}>
                  <Typography sx={{ fontWeight: 500 }}>Country Airport Code</Typography>
                  <Autocomplete
                    multiple
                    id='fixed-tags-demo'
                    disablePortal={false}
                    value={selectedCodes}
                    disableCloseOnSelect
                    onChange={(event, newValue: any) => {
                      setSelectedCodes(newValue);
                    }}
                    {...flatPropsCode}
                    size='small'
                    sx={{ width: '100%', maxHeight: '300px', overflowY: 'auto', py: '5px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Country Codes'
                        placeholder='Search Code here...'
                      />
                    )}
                  />
                </Stack>
                <FormHelperText>Selected : {selectedCodes.length}</FormHelperText>
              </Grid>
              <Grid container xs={12} direction='row-reverse'>
                <Stack justifyContent='space-between' sx={{ px: '1.5%' }}>
                  <Stack direction='row' spacing={1}>
                    <Button
                      data-testid='b1'
                      color='secondary'
                      variant='outlined'
                      onClick={props.onClose}
                      sx={{
                        fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                        bgcolor: '#f1f1f1',
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      data-testid='b2'
                      color='primary'
                      variant='outlined'
                      disabled={country === null || selectedCodes.length === 0}
                      onClick={handleAdd}
                      sx={{
                        fontSize: { xs: '0.75rem', sm: '0.75rem', md: '1rem' },
                        bgcolor: '#F5FBFE',
                      }}
                    >
                      {props.isEdit ? 'SAVE' : 'ADD'}
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
