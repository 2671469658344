import React, { useEffect, useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TravelContext } from '../store/TravelContext';
import { ReactComponent as HRGLogo } from '../assets/logo-hrg.svg';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import axios from 'axios';

export const Header = () => {
  const [image, setImage] = useState('');
  const [userName, setUserName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const travelCtx = useContext(TravelContext);

  const checkIfAdmin = async (token: string) => {
    try {
      const userGroups = await axios
        .get(
          `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$search="displayName:${process.env.REACT_APP_ADMIN_AD}"&$select=displayName`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              ConsistencyLevel: 'eventual',
            },
          }
        )
        .then((response) => response.data);
      if (
        userGroups.value.length > 0 &&
        userGroups.value[0].displayName === process.env.REACT_APP_ADMIN_AD
      ) {
        setIsAdmin(true);
        travelCtx.setAdmin(true);
      }
    } catch (err) {
      console.log('Error in fetching AD group details', err);
    }
  };
  const checkIfSuperAdmin = async (token: string) => {
    try {
      const userGroups = await axios
        .get(
          `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$search="displayName:${process.env.REACT_APP_SUPER_ADMIN_AD}"&$select=displayName`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              ConsistencyLevel: 'eventual',
            },
          }
        )
        .then((response) => response.data);
      if (
        userGroups.value.length > 0 &&
        userGroups.value[0].displayName === process.env.REACT_APP_SUPER_ADMIN_AD
      ) {
        setIsAdmin(true);
        travelCtx.setSuperAdmin(true);
      }
    } catch (err) {
      console.log('Error in fetching AD group details for super Admin', err);
    }
  };
  const getAccessToken = async (): Promise<string | undefined> => {
    if (accounts.length > 0) {
      [];
      const request = {
        scopes: ['User.Read'],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return response.accessToken;
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return response.accessToken;
        }
      }
    }
  };
  const getUserPhoto = async (token: string) => {
    try {
      const response = await axios(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'arraybuffer',
      });
      if (response.status === 200) {
        try {
          const image = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          setImage(`data:;base64,${image}`);
        } catch (e) {
          console.log('Error in profile image buffer', e);
        }
      }
    } catch (err) {
      console.log('Error in fetching profile image', err);
    }
  };

  useEffect((): void => {
    if (accounts && accounts[0] && accounts[0].name) {
      const nameArray = accounts[0].name?.replace(' - Network', '').split(' ');
      const name = `${nameArray[0]}`.concat(
        nameArray.length > 1 ? ` ${nameArray[nameArray.length - 1]}` : ''
      );
      setUserName(name);
    }
    (async (): Promise<void> => {
      const token = await getAccessToken();
      if (token) {
        await checkIfAdmin(token);
        await checkIfSuperAdmin(token);
        await getUserPhoto(token);
      }
    })();
  }, []);
  const handleNavigate = () => {
    if (location.pathname === '/' || location.pathname === '/notravel') {
      navigate('/admin');
    } else {
      navigate('/');
    }
  };
  const content = (
    <Stack direction='row' spacing={{ xs: 1, sm: 2 }} justifyContent='center' alignItems='center'>
      {isAdmin && (
        <Button
          color='primary'
          variant='outlined'
          onClick={handleNavigate}
          sx={{
            fontSize: { xs: '0.5rem', sm: '0.75rem', md: '1rem' },
            bgcolor: '#F5FBFE',
          }}
        >
          {location.pathname === '/' || location.pathname === '/notravel' ? 'Admin' : 'Home'}
        </Button>
      )}
      {image !== '' ? (
        <Avatar src={image} />
      ) : (
        <Avatar
          sx={{
            width: { xs: 36, sm: 40 },
            height: { xs: 36, sm: 40 },
            bgcolor: '#00749E',
            fontSize: '1.2rem',
          }}
        >
          {`${userName?.split(' ')[0]?.[0] ?? ''}${userName?.split(' ')[1]?.[0] ?? ''}`}
        </Avatar>
      )}

      <Typography
        color='secondary'
        sx={{
          fontSize: { xs: '0.75rem ', sm: '1.125rem' },
          fontWeight: '400',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        {userName}
      </Typography>
    </Stack>
  );

  return (
    <React.Fragment>
      <AppBar
        component='header'
        sx={{
          position: 'sticky',
          background: 'white',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            height: { sm: '2rem', md: '4rem' },
          }}
        >
          <SvgIcon
            component={HRGLogo}
            viewBox='0 0 1000 40'
            sx={{
              width: { sm: '70%', md: '40%', xs: '70%' },
              height: { xs: '2.5rem', sm: '3rem' },
            }}
          />
          {content}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
