import * as React from 'react';
import { Typography, Stack, Button, Divider } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { TravelContext } from '../store/TravelContext';
import { useMsal } from '@azure/msal-react';
import Grid from '@mui/material/Unstable_Grid2';
import Check from '@mui/icons-material/Check';
import Link from '@mui/material/Link';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import axios from 'axios';
import { Toast } from './Toast';

export const PostTravel = () => {
  let content;
  let posTravelContent;
  const [selectedValue, setSelectedValue] = React.useState({
    unusual_interaction: '',
    device_possessioned: '',
    device_unlocked: '',
  });
  const postTravelRecord = React.useContext(TravelContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [answer, setAnswer] = React.useState('');
  const { instance, accounts } = useMsal();
  const [toastState, setToastState] = React.useState({
    open: false,
    type: 'success',
    message: '',
  });
  React.useEffect(() => {
    setSelectedValue({
      unusual_interaction: '',
      device_possessioned: '',
      device_unlocked: '',
    });
  }, [postTravelRecord]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'unusual_interaction')
      setSelectedValue({ ...selectedValue, unusual_interaction: event.target.value });
    if (type === 'device_possessioned')
      setSelectedValue({ ...selectedValue, device_possessioned: event.target.value });
    if (type === 'device_unlocked')
      setSelectedValue({ ...selectedValue, device_unlocked: event.target.value });
  };

  const getAccessToken = async (): Promise<
    { accessToken: string; idToken: string } | undefined
  > => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`User.Read`],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return { accessToken: response.accessToken, idToken: response.idToken };
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request);
          return { accessToken: response.accessToken, idToken: response.idToken };
        }
      }
    }
  };

  const handleSubmit = async () => {
    const extra = { ...selectedValue, answer };
    const token = await getAccessToken();
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const config = {
      method: 'post',
      url: `${apiUrl}/submitForm`,
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
        'Content-Type': 'application/json',
        graph_token: token?.accessToken,
      },
      data: {
        id: postTravelRecord.travelRecords.id,
        other_details: { form_fields: extra },
      },
    };
    try {
      setIsLoading(true);
      const response = await axios(config);
      if (response.data.status !== 200) {
        throw Error(response.data.msg);
      }
      postTravelRecord.travelRecords.extra_details = {
        ...postTravelRecord.travelRecords.extra_details,
        other_details: { form_fields: extra },
      };
      postTravelRecord.setTravelRecords({ ...postTravelRecord.travelRecords });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(true);
      setToastState({ open: true, message: 'Form submission failed', type: 'error' });
    }
  };

  if (postTravelRecord.travelRecords.extra_details?.other_details?.form_fields === undefined) {
    content = (
      <>
        <Stack justifyContent={'center'}>
          <Stack sx={{ mx: 3 }}>
            <Typography
              sx={{
                fontSize: '1.10rem',
                fontWeight: 'bold',
                mb: '0.5rem',
              }}
            >
              Mandatory Security Questionnaire
            </Typography>
            <Typography
              sx={{
                fontSize: '1.05rem',
                fontWeight: 'medium',
              }}
            >
              Hi{' '}
              {`${
                accounts && accounts[0] && accounts[0].name ? accounts[0].name?.split(' ')[0] : ''
              }`}
              , When you submit this form, the owner will see your name and email address.
            </Typography>
            <Divider sx={{ mt: 1 }} />
          </Stack>
          <FormControl sx={{ mx: 3, my: 1 }}>
            <FormLabel sx={{ mt: 1, mb: 1 }} focused={false}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                Did you experience any odd or unusual interactions during your trip?
                <sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue.unusual_interaction === 'yes'}
                    onChange={(e) => handleChange(e, 'unusual_interaction')}
                    value='yes'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'Yes' }}
                  />
                }
                label='Yes'
              />
              <FormControlLabel
                control={
                  <Radio
                    data-testid='r1'
                    checked={selectedValue.unusual_interaction === 'no'}
                    onChange={(e) => handleChange(e, 'unusual_interaction')}
                    value='no'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'No' }}
                  />
                }
                label='No'
              />
            </RadioGroup>
            <FormLabel sx={{ mt: 1, mb: 1 }} focused={false}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                If yes, please describe the odd or unusual interaction briefly:
                <br />
              </Typography>
              <Typography variant='subtitle2' sx={{ fontStyle: 'italic' }}>
                Note : Do not input personal information(PI) into the field. Refer to the&nbsp;
                <Link
                  href='https://lilly-qualitydocs.veevavault.com/ui/#doc_info/2972601/7/0?state_type=steady_state__v'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Global PI Classification Chart
                </Link>
                &nbsp;to understand what constitutes as PI.
              </Typography>
            </FormLabel>
            <TextField
              label='Enter your answer'
              value={answer}
              multiline
              rows={4}
              inputProps={{ maxLength: 100 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAnswer(event.target.value);
              }}
              disabled={selectedValue.unusual_interaction === 'no'}
            />
            <FormHelperText>{answer.length}/100</FormHelperText>
            <FormLabel sx={{ mt: 1, mb: 1 }} focused={false}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                Did you feel targeted on your trip for activities outside of the purpose of the
                trip?
                <sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue.device_possessioned === 'yes'}
                    onChange={(e) => handleChange(e, 'device_possessioned')}
                    value='yes'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'Yes' }}
                  />
                }
                label='Yes'
              />
              <FormControlLabel
                control={
                  <Radio
                    data-testid='r2'
                    checked={selectedValue.device_possessioned === 'no'}
                    onChange={(e) => handleChange(e, 'device_possessioned')}
                    value='no'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'No' }}
                  />
                }
                label='No'
              />
            </RadioGroup>
            <FormLabel sx={{ mt: 1, mb: 1 }} focused={false}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                Were you asked to unlock or provide access to any of your electronic devices?
                <sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue.device_unlocked === 'yes'}
                    onChange={(e) => handleChange(e, 'device_unlocked')}
                    value='yes'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'Yes' }}
                  />
                }
                label='Yes'
              />
              <FormControlLabel
                control={
                  <Radio
                    data-testid='r3'
                    checked={selectedValue.device_unlocked === 'no'}
                    onChange={(e) => handleChange(e, 'device_unlocked')}
                    value='no'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'No' }}
                  />
                }
                label='No'
              />
            </RadioGroup>
          </FormControl>
          <Button
            data-testid='b1'
            color='primary'
            variant='contained'
            sx={{ ml: '1.5rem', fontSize: { xs: 'small', sm: 'medium' } }}
            style={{ width: '6rem' }}
            disabled={
              !(
                selectedValue.unusual_interaction !== '' &&
                selectedValue.device_possessioned !== '' &&
                selectedValue.device_unlocked !== ''
              ) ||
              (selectedValue.unusual_interaction === 'yes' ? answer.length === 0 : false) ||
              isLoading
            }
            onClick={handleSubmit}
          >
            <Typography sx={{ color: 'white', fontWeight: 'medium' }}>
              {isLoading ? 'Saving...' : 'Submit'}
            </Typography>
          </Button>
        </Stack>
        {toastState.open && (
          <Toast
            open={toastState.open}
            handleClose={() => setToastState({ ...toastState, open: false })}
            message={toastState.message}
            type={toastState.type}
          />
        )}
      </>
    );
  } else {
    content = (
      <>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={1}
          sx={{ py: '2rem' }}
        >
          <Check
            sx={{
              backgroundColor: '#45BA7F',
              width: 50,
              height: 50,
              borderRadius: '50%',
              color: 'white',
            }}
          />
          <Typography
            sx={{
              fontSize: '1.10rem',
              fontWeight: 'bold',
            }}
          >
            Thank You,
            {` ${accounts && accounts[0] && accounts[0].name ? accounts[0].name : ''}`}
          </Typography>
          {Object.values(
            postTravelRecord.travelRecords.extra_details.other_details.form_fields
          ).includes('yes') ? (
            <>
              <Typography
                sx={{
                  fontSize: '1.10rem',
                  fontWeight: 'medium',
                  width: '80%',
                  pl: { xs: '1rem', sm: '2rem' },
                }}
              >
                Thank you for completing the security questionnaire. Your loaner device will be
                triaged for investigation by our Information Security team. Please follow these
                steps:
              </Typography>
              <Typography
                variant='subtitle2'
                color='#D52B1E'
                sx={{ fontSize: '1.10rem', width: '80%', pl: { xs: '1rem', sm: '2rem' } }}
              >
                1) Disconnect from the internet and shut down your loaner device(s) immediately. Do
                not power back on unless instructed to do so by the IT Service Desk.
                <br />
                2) Your loaner device(s) needs to be returned to the Information Security team
                immediately. An analyst will call/contact you to make arrangements for your
                device(s).
              </Typography>
            </>
          ) : (
            <Typography
              variant='subtitle1'
              sx={{ fontSize: '1.10rem', width: '80%', pl: { xs: '1rem', sm: '2rem' } }}
            >
              Protect Lilly travel requirements have been successfully completed. Thank you for
              helping to protect Lilly.
            </Typography>
          )}
          <Grid sx={{ backgroundColor: '#f5f5f5', p: '1rem', width: '80%' }}>
            <Typography
              sx={{
                fontSize: '1.10rem',
              }}
            >
              If you would like to provide feedback on your experience with the Traveling to Higher
              Risk Geographies process, please complete this optional survey: <br />
              <Link
                href='https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgIshayfcz5VAm0M2KNw2e5VUNEo4VVpWOEY4NUY2UlVUTFZURkpWT1g3UiQlQCN0PWcu'
                target='_blank'
                rel='noreferrer noopener'
                color='#00a1de'
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Take the survey
              </Link>
            </Typography>
          </Grid>
        </Stack>
      </>
    );
  }

  if (
    postTravelRecord.travelRecords.ritm_number &&
    ['OPEN', 'COMPLETE'].includes(postTravelRecord.travelRecords.extra_details?.ritm_status)
  ) {
    posTravelContent = (
      <>
        <Grid sx={{ backgroundColor: '#f5f5f5', p: '1rem', mx: 3, mb: 1 }}>
          <Typography
            sx={{
              fontSize: '1.10rem',
              fontWeight: 'medium',
            }}
          >
            Welcome back from a higher-risk geography with our loaner device(s).
            <br />
            Take these steps:
          </Typography>
          <Typography
            sx={{
              fontSize: '1.0rem',
            }}
          >
            1. To avoid account lockout, reset your password within 48 hours of your return by
            following&nbsp;
            <Link
              href='https://sspr.lilly.com/jobaids#changePassword/'
              target='_blank'
              rel='noreferrer noopener'
              color='#00a1de'
            >
              these instructions.
            </Link>
            <br />
            2. Complete&nbsp;
            <Link
              color='#00a1de'
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                window.scrollTo({ top: document.body.scrollHeight / 2.1, behavior: 'smooth' });
              }}
            >
              this security questionnaire
            </Link>
            &nbsp;before returning your loaner device(s).
            <br />
            3. Follow emailed instructions based on questionnaire results.
            <br />
            4. Return devices within 5 days.
            <br />
            Thank you for all you do to protect Lilly.
          </Typography>
        </Grid>
        <Divider sx={{ mt: 2, mx: 3 }} />
        {content}
      </>
    );
  } else {
    posTravelContent = (
      <Grid sx={{ mx: 2, mb: 1, textAlign: 'center' }}>
        <Typography
          sx={{
            fontSize: '1.10rem',
            fontWeight: 'medium',
          }}
        >
          No Action Required
        </Typography>
      </Grid>
    );
  }

  return posTravelContent;
};
